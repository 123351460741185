import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as LogoLight } from '../../images/logos/nodejs-logo-light-mode.svg';
import { ReactComponent as LogoDark } from '../../images/logos/nodejs-logo-dark-mode.svg';
import { CommonComponents } from '../../components';
import styles from './index.module.scss';

const Header = (): JSX.Element => (
  <nav aria-label="Primary" className={styles.header}>
    <div className={styles.container}>
      <div className={styles.startWrapper}>
        <Link to="/" aria-label="Homepage">
          <div className={styles.logo}>
            <LogoLight className="light-mode-only" />
            <LogoDark className="dark-mode-only" />
          </div>
        </Link>
      </div>

      <ul className={styles.tabs}>
        <li>
          <Link to="/learn/" activeClassName={styles.active} partiallyActive>
            <FormattedMessage id="components.header.links.learn" />
          </Link>
        </li>
        
            <li>
            <a href="https://nodejs.cn/api/" target="_blank">API 文档</a>
            </li>
            <li>
            <a href="https://nodejs.cn/download/" target="_blank">下载安装</a>
            </li>
            </ul>
            

      <div className={styles.endWrapper}>
        <ul className={styles.rightContainer}>
          
          <li>
            <a href="https://nodejs.cn" target="_blank" style={{textDecoration:'none'}}>
            更多文档
            </a>
          </li>

          <li>
            <CommonComponents.DarkModeToggle />
          </li>

          

          
        </ul>
      </div>
    </div>
  </nav>
);

export default Header;
