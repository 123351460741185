import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useDetectOs } from '../../../hooks/useDetectOs';
import type { NodeReleaseData } from '../../../types';
import styles from './index.module.scss';

interface Props {
  title: string;
  subTitle?: string;
  nodeReleaseData: NodeReleaseData[];
}

const Hero = ({ title, subTitle, nodeReleaseData }: Props): JSX.Element => {
  const { getDownloadLink } = useDetectOs();

  const currentLTS = nodeReleaseData.find(release => release.isLts);
  const currentRelease = nodeReleaseData.find(
    release => release.status === 'Current'
  );

  const ltsVersionUrl = getDownloadLink(currentLTS?.fullVersion || '');
  const currentVersionUrl = getDownloadLink(currentRelease?.fullVersion || '');

  return (
    <div className={styles.hero}>
      <h1>随处运行 JavaScript</h1>
      <h2 className={styles.subTitle}>{subTitle}
          <div>允许开发人员在浏览器之外编写命令行工具和服务器端脚本。</div>
          </h2>
      <div className={styles.buttonsContainer}>
        
          <div className={styles.downloadLtsContainer}>
          <a className={styles.downloadButton} href="https://nodejs.cn/download/" target="_blank">
            下载 Node
          </a>
          </div>
          

        <Link className={styles.downloadButtonInverse} to="/learn">
          <FormattedMessage id="components.hero.learn" />
        </Link>
      </div>
    <div id="pagead" style={{margin:"30px 10px 0",maxWidth:"350px"}}><div className="wwads-cn wwads-horizontal" data-id="242"></div></div>
</div>);
};

export default Hero;
