exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-branding-tsx": () => import("./../../../src/pages/about/branding.tsx" /* webpackChunkName: "component---src-pages-about-branding-tsx" */),
  "component---src-pages-about-governance-tsx": () => import("./../../../src/pages/about/governance.tsx" /* webpackChunkName: "component---src-pages-about-governance-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-releases-tsx": () => import("./../../../src/pages/about/releases.tsx" /* webpackChunkName: "component---src-pages-about-releases-tsx" */),
  "component---src-pages-about-security-tsx": () => import("./../../../src/pages/about/security.tsx" /* webpackChunkName: "component---src-pages-about-security-tsx" */),
  "component---src-pages-download-index-tsx": () => import("./../../../src/pages/download/index.tsx" /* webpackChunkName: "component---src-pages-download-index-tsx" */),
  "component---src-pages-download-package-manager-tsx": () => import("./../../../src/pages/download/package-manager.tsx" /* webpackChunkName: "component---src-pages-download-package-manager-tsx" */),
  "component---src-pages-download-releases-tsx": () => import("./../../../src/pages/download/releases.tsx" /* webpackChunkName: "component---src-pages-download-releases-tsx" */),
  "component---src-pages-get-involved-collab-summit-tsx": () => import("./../../../src/pages/get-involved/collab-summit.tsx" /* webpackChunkName: "component---src-pages-get-involved-collab-summit-tsx" */),
  "component---src-pages-get-involved-contribute-tsx": () => import("./../../../src/pages/get-involved/contribute.tsx" /* webpackChunkName: "component---src-pages-get-involved-contribute-tsx" */),
  "component---src-pages-get-involved-index-tsx": () => import("./../../../src/pages/get-involved/index.tsx" /* webpackChunkName: "component---src-pages-get-involved-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-templates-api-tsx": () => import("./../../../src/templates/api.tsx" /* webpackChunkName: "component---src-templates-api-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-learn-tsx": () => import("./../../../src/templates/learn.tsx" /* webpackChunkName: "component---src-templates-learn-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

