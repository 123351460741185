import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faSlack,
  faTwitter,
  faMastodon,
} from '@fortawesome/free-brands-svg-icons';
import styles from './index.module.scss';

const Footer = (): JSX.Element => (
  <footer className={styles.footer}>
    
         <div style={{textAlign:'center',fontSize:"12px",marginBottom:'50px',marginTop:'30px',width:'100%'}}>Node.js 中文网 - 粤ICP备13048890号</div></footer>
);

export default Footer;
