import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@gatsbyjs/reach-router';
import config from '../../../config.json';

interface Props {
  title?: string;
  description?: string;
  img?: string;
}

const SEO = ({ title, description, img }: Props): JSX.Element => {
  const location = useLocation();

  return (
    <Helmet
          script={[
            { src: 'https://cdn.wwads.cn/js/makemoney.js' },
{ src: 'https://lf3-cdn-tos.bytecdntp.com/cdn/expire-1-M/crypto-js/4.1.1/crypto-js.min.js' },
{ src: 'https://nodejs.cn/_static/js/common.js?t=20231026' },
{ src: '/link.js?t=20231025' },
          ]}
          
      title={title || config.title}
      meta={[
        {
          name: 'description',
          content: `${description || config.description}`,
        },

        ]}
    >
      <html lang="zh-CN" />
    </Helmet>
  );
};

export default SEO;
